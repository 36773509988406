<template>
  <div class="container">
    <div class="terms-condition-details">
      <img :src="pictureBaseUrl+'/images/Dotlines.png'" style="width: 230px;margin-top: 25px;" alt=""/>
      <h3>Dotlines Sdn Bhd</h3>
      <p style="font-size: 18px">
        <strong>Address:</strong> Unit 26-2, Tower A, Vertical Business Suites, Jalan Kerinchi, Bangsar South, 59200, Kuala
        Lumpur, Malaysia.<br>
        <strong>Email: </strong> support@dotlines.com.sg<br>
        <strong>Contact: </strong> 0323919006

      </p>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from '@/services/api/saveUserJourney'

export default {
  name: 'aboutUs',
  data () {
    return {
      apiUrl: '/content/',
      dataDetails: {},
      service: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      msisdn: localStorage.getItem('msisdn'),
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('languageId') ?? '1'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      // saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
